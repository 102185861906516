:root {
    --color-primary: #ff6600;
    --color-white: #FFFFFF;
    --color-grey: #d9d9d9;
}


* {
    box-sizing: border-box;
}

.text-center {
    text-align: center;
    font-family: "DM Sans", sans-serif;

}

#main {}

#hero {
    position: relative;
}

#hero .hero__image {
    display: block;
    width: 100%;
}

#hero .hero__btn {
    font-family: "DM Sans", sans-serif;
    position: absolute;

    display: block;
    bottom: 15%;
    left: 0;
    right: 0;
    max-width: 150px;
    margin: 0 auto;
    background-color: var(--color-primary);
    color: var(--color-white);
    outline: none;
    border: 3px solid var(--color-white);
    font-weight: 400;
    font-size: 20px;
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.section-title {
    text-transform: uppercase;
    font-family: "Jost", sans-serif;
    font-size: 28px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 16px;
    color: #1D1B1B;
}

#leaderboard {
    max-width: 650px;
    margin: 0 auto;
    padding: 50px 24px;
}



#leaderboard ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#leaderboard ul li {
    font-family: "Jost", sans-serif;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-grey);
    padding: 0px 24px 0px 0;
    border-radius: 50px;
    color: #191919;
}

#leaderboard ul li .name {
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

#leaderboard ul li .id {
    background-color: var(--color-primary);
    color: var(--color-white);
    border-radius: 50px;
    width: 80px;

    display: inline-block;
    text-align: center;
    padding: 7px 0;
    margin-right: 16px;
}

@media only screen and (max-width:450px) {
    #leaderboard ul li .id {
        padding: 16px 0;
    }
}


#slider {
    padding-bottom: 60px;
}

#slider .slick-dots {
    bottom: -24px;
}

#slider .slick-dots button::before {
    content: ""
}

#slider .slick-dots button {
    width: 7px;
    height: 7px;
    border-radius: 7;
    background-color: var(--color-grey);
}

#slider .slick-dots .slick-active button {

    background-color: var(--color-primary);
}

/* Reward Slider */
#reward-slider {
    max-width: 1150px;
    margin: 0 auto;
    padding: 24px;
    margin-bottom: 24px;
    /* overflow: hidden; */
    /* z-index: 10000; */

}

@media only screen and (max-width:1200px) {
    #reward-slider {
        width: 960px;
    }

}

@media only screen and (max-width:1080px) {
    #reward-slider {
        width: 800px;
    }

}

@media only screen and (max-width:860px) {
    #reward-slider {
        width: 500px;
    }

}

@media only screen and (max-width:600px) {
    #reward-slider {
        width: 80%;
    }
}

#reward-slider .reward-slider__item {
    background-color: var(--color-white);
    border-radius: 16px;
    text-align: center;
}

#reward-slider .reward-slider__item img {
    border-radius: 16px;
    height: 200px;
    width: 200px;
    padding: 24px;
    object-fit: contain;
    margin: 0 auto;
}

/* the slides */
#reward-slider .slick-list {
    margin: 0 -5px;
}

#reward-slider .slick-slide>div {
    padding: 0 5px;
}

#reward-slider .slick-arrow {
    /* width: 40px;

    height: 40px; */

}

#reward-slider .slick-arrow::before {
    font-size: 38px;
    color: var(--color-white);
    opacity: 1;
}

#reward-slider .slick-prev {
    left: -50px;
}

#reward-slider .slick-next {
    right: -33px;
}

#reward-slider .images-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
}

@media only screen and (max-width:860px) {
    #reward-slider .images-wrapper {
        grid-template-columns: 1fr;

    }
}

#reward-slider .images-wrapper img {
    width: 200px;
    height: 200px;
    background-color: #FFF;
}

/* Terms and Conditions */
#terms {
    max-width: 1100px;
    margin: 0 auto;
    padding-bottom: 24px;
}

#terms .terms-conditions h2 {
    font-size: 18px;
    font-weight: 600;
}

#terms .terms-conditions {
    background-color: var(--color-white);
    border-radius: 16px;
    font-family: "Jost", sans-serif;
    padding: 24px;
    /* text-align: center; */
    line-height: 1.5;
    /* max-height: 320px;
    overflow-y: auto; */
}

#terms ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}


/* 
#terms .terms-conditions ul::-webkit-scrollbar {
    width: 10px;
}

#terms .terms-conditions ul::-webkit-scrollbar-track {
    background: #f1f1f1;
}

#terms .terms-conditions ul::-webkit-scrollbar-thumb {
    background: #888;
}

#terms .terms-conditions ul::-webkit-scrollbar-thumb:hover {
    background: #555;
} */

@media only screen and (max-width:1200px) {
    #terms {
        width: 960px;
    }

}

@media only screen and (max-width:1080px) {
    #terms {
        width: 800px;
    }

}


@media only screen and (max-width:860px) {
    #terms {
        width: 500px;
    }

}



@media only screen and (max-width:600px) {
    #terms {
        width: 90%;
    }
}

/* Quiz */

#user-detail-form {
    max-width: 650px;
    margin: 0 auto;
    padding: 50px 24px;
}

#user-detail-form label {
    text-transform: uppercase;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
}

#user-detail-form .ant-form-item-label {
    text-align: left;
}

#user-detail-form .submit-item .ant-form-item-control-input-content {
    text-align: center;
}

#user-detail-form .ant-input-number-handler-wrap {
    display: none !important;
}

#user-detail-form .ant-input-number {
    border: none;
    border-radius: 50px;
}


#user-detail-form .ant-input-affix-wrapper {
    padding: 0;
    border: none;
}

#user-detail-form .ant-input-affix-wrapper:focus {
    box-shadow: none;
    /* background-color: red; */
}

#user-detail-form .ant-input-affix-wrapper:focus-within {
    box-shadow: none;
    /* background-color: red; */
}

#user-detail-form input,
#user-detail-form .ant-select-selector {
    background-color: var(--color-grey);
    border: none;
    outline: none;
    border-radius: 50px;
    font-family: 'Jost', sans-serif;
    font-size: 18px;
}

#user-detail-form input {
    padding: 7px 24px;
    border: 1px solid var(--color-grey);

}

#user-detail-form .ant-select-selector {
    padding: 7px 24px;
    border: 1px solid var(--color-grey);
    box-shadow: none;

}

#user-detail-form .city-item .ant-select {
    height: auto;
}


#user-detail-form .start-btn {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 5px 50px;

}

#user-detail-form input:focus {
    border: 1px solid var(--color-primary);
    outline: none;
    box-shadow: none;
}

#user-detail-form .ant-select-open .ant-select-selector {
    border: 1px solid var(--color-primary);
    outline: none;
    box-shadow: none;
}

#questions,
#thankyou {
    max-width: 600px;
    margin: 0 auto;
    padding: 50px 24px;
}

.question_item {}

.question_item h3 {
    text-align: center;
    margin: 0 0 30px 0;
    font-size: 20px;
    font-family: 'DM Sans', sans-serif;

}


.progress-bar {
    font-family: 'DM Sans', sans-serif;
    font-weight: 900;
}

.options-group {
    margin-bottom: 24px;
}

.options-group .ant-radio-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    gap: 24px;
}

@media only screen and (max-width:600px) {
    .options-group .ant-radio-group {
        grid-template-columns: 1fr;

    }
}

.options-group .ant-radio-group label {
    font-family: 'DM Sans', sans-serif;
    border: none !important;
    color: var(--color-white);
    border-radius: 13px !important;
    font-size: 18px;
    height: auto;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.options-group .ant-radio-group label:hover {
    color: var(--color-white);
    background-color: var(--color-primary) !important;
}

.options-group .ant-radio-group label::before {
    display: none;
}

.options-group .ant-radio-group label:first-child {
    border-radius: 0;
}

.options-group .ant-radio-group label:last-child {
    border-radius: 0;
}

.ant-radio-button-wrapper-checked {
    background-color: var(--color-primary) !important;
}

.step-btn {
    background-color: var(--color-primary);
    color: var(--color-white);
    height: auto;
    padding: 5px 50px;
    border-radius: 13px;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    outline: none;
    border: none;
}

.step-btn:hover {
    background-color: var(--color-primary) !important;
    color: var(--color-white) !important;



}

#score {
    background-color: #EEEEEE20;
    text-align: center;
    padding: 30px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 13px;
    margin-bottom: 24px;
    border:
        3px dashed #77777750;
}

#score h1,
#score h2 {
    margin: 0;
}

#score h1 {
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    font-size: 24px;
}

#score h1 {
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    font-size: 72px;
}

.timer-wrapper {
    position: absolute;

    top: 16px;
    right: 24px;

}

.timer-wrapper .timer {
    font-size: 24px;
    font-family: "Jost", sans-serif;
    font-weight: 600;
}

@media only screen and (max-width:600px) {
    .timer-wrapper {
        position: unset;
    }
}



/* Change the color of the scrollbar thumb */
.simplebar-scrollbar::before {
    background-color: #ff6600 !important;
}


/* Footer */
footer {
    background-color: #FFF;
    font-family: "Roboto", sans-serif;
    padding: 0 70px 47px 70px;
    margin-bottom: 59px;
}

footer .border {
    border-top: 1px solid #000;
    max-width: 1700px;
    margin: 0 auto;

}

footer .footer-header {
    max-width: 1300px;
    /* padding-top: 115px; */
    padding-top: 7%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 24px;
}

footer .footer-header img {
    max-width: 130px;
    width: 100%;
}

footer .footer-header .footer-header_text-links {
    text-transform: uppercase;
    color: #000;
    font-size: 34px;
    font-weight: 600;
}

footer .footer-header .footer-header_text-links a {
    all: unset;
    cursor: pointer;
}

@media only screen and (max-width:767px) {
    footer .footer-header img {
        max-width: 240px;
        width: 100%;
    }

    footer .footer-header {
        flex-direction: column;
        align-items: start;
    }

    footer .footer-header .footer-header_text-links {
        font-size: 18px;
    }


}

footer .footer-columns {
    padding-top: 60px;
    max-width: 1300px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

footer .footer-column .footer-heading {
    font-size: 13px;
    color: #F36F31;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 46px;

}

footer .footer-column ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

footer .footer-column ul li {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #54595F;
}

footer .footer-column ul li a {
    all: unset;
    cursor: pointer;
}

footer .footer-column .social-links li a {
    display: flex;
    gap: 10px;

}

footer .footer-column form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

footer .footer-column form input {
    border: 1px solid #000;
    font-size: 17px;
    padding: 10px;
    outline: none;
    height: 40px;
}

footer .footer-column input[type="email"] {
    border-right: none;
    width: 75%;
}

footer .footer-column .submit-btn {
    font-size: 13px;
    background-color: transparent;
    color: #f36f31;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    border: 1px solid #000;
    border-radius: 0;

}

/* footer .footer-column input[type="submit"] {
    font-size: 13px;
    background-color: transparent;
    color: #f47629;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
} */

footer .footer-column .socials {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 5px;
    margin-top: 10px;
}

footer .footer-column .socials div:first-child,
footer .footer-column .socials div:last-child {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 5px;
    margin-top: 10px;
}

footer .footer-column .socials a {
    background-color: #f36f31;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

footer .footer-column .socials .img-link {
    width: auto;
    height: 30px;
    background-color: transparent;

}

footer .footer-column .socials svg {
    color: #FFF;
}

/* @media only screen and (max-width:1024px) {
    footer .footer-columns {
        grid-template-columns: 1fr 1fr;
    }
} */

@media only screen and (max-width:767px) {
    footer {
        padding: 24px 7% 47px 7%;
        margin-bottom: 59px
    }

    footer .footer-columns {
        grid-template-columns: 1fr;
        padding-top: 0;
    }

    footer .footer-column .socials {
        flex-direction: column;
        align-items: start;
    }

    footer .footer-column .socials div:last-child {
        flex-direction: column;
    }

}

.footer-bottom {
    background: #15141c;
    padding: 20px 0 22px 0;

}

.footer-bottom p {
    font-family: "Roboto", sans-serif;
    width: 86%;
    max-width: 1300px;
    margin: 0 auto;
    /* padding: 0 10px; */
}

.footer-bottom p a {
    all: unset;
}

/* 
#footer {
    font-family: "DM Sans", sans-serif;

    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media only screen and (max-width:768px) {
    #footer {
        grid-template-columns: 1fr;
    }
}

#footer .column {
    width: 100%;
    padding: 16px;
}

#footer .column ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

#footer .column ul li {
    font-size: 16px;
    color: #FFF;
    font-weight: 600;
    margin-bottom: 10px;
}

#footer .column ul li a {
    color: #FFF;
    text-decoration: none;
}

#footer .column .col-1 {}

#footer .col-1 img {
    max-width: 150px;
    width: 100%;
    margin-bottom: 20px;
}

#footer .col-1 p {
    font-size: 16px;
    color: #FFF;

}

#footer .col-1 p a {


    color: #f47629;

}


#footer .footer-heading {
    font-size: 22px;
    color: #f47629;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 30px;

}

.icon-list li {
    display: flex;
    align-items: flex-start;
}

.icon-list li>*:first-child {
    width: 24px;
    margin-right: 10px;
} */