#header-m {
    display: none;
}

#header-d {
    width: 100%;
    height: 103px;
    background-color: #FFF;
    margin: 0 auto;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#header-d .logo img {
    max-width: 103.95px;
    /* width: 100%; */
}

.ant-menu-horizontal>.ant-menu-item::after,
.ant-menu-horizontal>.ant-menu-submenu::after {
    border-bottom: none !important;
    transition: none !important;
}

#header-d .ant-menu {
    font-family: "Roboto", sans-serif !important;
    font-size: 17px;
    font-weight: 500;
}

#header-d .ant-menu .ant-menu-item,
#header-d .ant-menu .ant-menu-submenu {
    padding: 0;
    margin: 0 27px;
}

@media only screen and (max-width:1200px) {

    #header-d .ant-menu .ant-menu-item,
    #header-d .ant-menu .ant-menu-submenu {
        margin: 0 12px;
    }
}

#header-d .ant-menu .ant-menu-item:first-child {
    margin-left: 0;
}

#header-d .ant-menu .ant-menu-item:nth-last-child(2) {
    margin-right: 0;
}

#header-d .ant-menu-title-content:hover {

    color: #ff6600;
}

.ant-menu-title-content,
.link-item {
    font-weight: 500;
    color: #000;
    transition: color 0.3s ease-in-out;
}

.link-item:hover {
    transition: color 0.3s ease-in-out;
    color: #ff6600 !important;

}

.link-item.active {
    color: #ff6600 !important;

}

/* New styles */

.header-right {
    width: 100%;
    display: flex;
    align-items: center;
}

.hamburger {
    background: #15141c;
    max-width: 114px;
    width: 100%;
    height: 103px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 54px;
    cursor: pointer;
}

.hamburger svg {
    color: #FFF;
    transition: color 0.3s ease-in-out;
}

.hamburger:hover svg {
    color: #ff6600;
    transition: color 0.3s ease-in-out;
}

.ant-menu-submenu-popup {
    top: 103px !important;
    padding: 0;

    max-width: 250px !important;
    width: 100%;
}

.ant-menu-submenu-popup ul {
    background-color: #15141c !important;
    border-radius: 0 !important;
    padding: 20px 0 !important;
}

.ant-menu-submenu-popup ul li {
    margin: 0 !important;
}

.ant-menu-submenu-popup ul li div {
    height: auto !important;
    margin: 0 !important;
}

.ant-menu-submenu-popup ul li .link-item {
    padding: 0 24px;
}

.ant-menu-submenu-popup ul .ant-menu-item-selected,
.ant-menu-submenu-popup ul .ant-menu-submenu-title:active {
    background-color: #15141c !important;
}

/* .ant-menu-submenu-popup ul li div, */

.ant-menu-submenu-popup .ant-menu-submenu-expand-icon,
.ant-menu-submenu-popup ul li .link-item {
    color: #c1c1c1 !important;
    font-size: 16px;
    font-weight: 400;
}

.ant-menu-submenu-popup ul li div:hover .ant-menu-submenu-expand-icon {
    color: #ff6600 !important;

}

.ant-menu-submenu-popup ul li div:hover .link-item,
.ant-menu-submenu-popup ul li:hover .link-item {
    color: #FFF !important;

}



/* Hamburger */
#desktop-drawer {}

#desktop-drawer .close-hamburger {
    width: 103px;
    height: 103px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff7618;
    cursor: pointer;
}

#desktop-drawer .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
}

#desktop-drawer .body {
    /* background-color: red; */
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
    flex: 1;
    gap: 24px;
}

#desktop-drawer .body img {
    max-width: 126px;
    width: 100%;
    margin-bottom: 24px;
}

#desktop-drawer .body form {
    display: flex;
}

#desktop-drawer .body form input[type="text"] {
    border: none;
    border-bottom: 1px solid #000;
    width: 70%;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    padding: 3px 17px;
    font-size: 17px;
}

#desktop-drawer .body form input[type="submit"] {
    border: none;
    background-color: #15141c;
    border: 1px solid #15141c;
    color: #FFF;
    font-size: 17px;
    margin-left: 1em;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    padding: 7px 17px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

}

#desktop-drawer .body form input[type="submit"]:hover {
    background-color: #ff7618;
    border-color: #ff7618;
    transition: all 0.3s ease-in-out;

}


#desktop-drawer .body ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

#desktop-drawer .body ul li a {
    all: unset;
    font-size: 16px;
    color: #000;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: 400;

}

#desktop-drawer .body ul li a:hover {
    color: #ff7618;
    transition: all 0.3s ease-in-out;

}

#desktop-drawer .body ul li {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    margin-bottom: 7px;
}

#desktop-drawer .body .socials {
    display: flex;
    gap: 10px;
}

#desktop-drawer .body .socials svg {
    color: #ff7618;
    width: 30px;
    height: 30px;
    transition: all 0.3s ease-in-out;
    padding: 3px;
}

#desktop-drawer .body .socials svg:hover {
    padding: 0px;
    transition: all 0.3s ease-in-out;
}

#desktop-drawer .body .headingText {
    font-size: 16px;
    font-weight: 700;
}

@media only screen and (max-width:1024px) {
    #header-d {
        display: none;
    }

    #header-m {
        display: flex;
    }
}

#header-m {
    background-color: #FFF;
    height: 70px;
    justify-content: space-between;
    align-items: center;
    padding: 0 7%;
    position: relative;
    z-index: 9999999;
}

#header-m .logo img {
    height: 59.98px;
}

#header-m .hamburger-m {
    background-color: #ff7618;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* #header-m .hamburger-m svg {
    width: 33px;
} */

#mobile-drawer {
    top: 70px;
}

.ant-drawer-mask {
    /* display: none; */
}

.ant-drawer-top {
    top: 70px;

}

.ant-drawer-top .ant-drawer-content-wrapper {
    box-shadow: none;
}

#mobile-drawer .ant-menu-inline {
    border: 0;
}

#mobile-drawer .ant-menu-submenu-title:hover,
#mobile-drawer .ant-menu-sub {
    background-color: #FFF;
}